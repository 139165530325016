<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard color="primary">
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left text-white">My Ticket</h4> 
            <button type="button"  @click="modalAdd = true" class="float-right btn btn-warning form-control-sm">New Ticket</button>

          </slot>
        </CCardHeader>
        <CCardBody>
        <div v-for="row in items" :key="row.no" v-if="itemlengths>0">
        <CCard color="dark" accent-color="secondary" >
          <CCardBody style="font-size: 11px;">
            <CRow>
              <CCol @click="showModal(row.ticket_title, row.ticket_content, row.id_ticket)">
                <div class="text-left text-white">
                  <strong>{{row.ticket_title}}</strong><br>
                  <span class="text-white small">#{{row.id_ticket}} | {{row.ticket_timecreated}}</span>
                </div>
                <br>
                <span class='text-white'>{{row.ticket_content}}</span>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        
        <CCard class="ml-4" color="secondary" v-for="rs in row.reply" :key="rs.id_replyticket" v-if="row.reply.length>0">
          <CCardBody style="font-size: 11px;">
            <CRow>
              <CCol>
                <div class="text-left text-white">
                  <strong>{{rs.reply}}</strong><br>
                  <span class="text-white small">#{{rs.id_ticket}} | {{rs.timereply}}</span>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        </div>

        <CCard accent-color="danger" v-if="itemlengths==0">
          <CCardBody style="font-size: 11px;">
            <CRow>
              <CCol>
                <div class="text-center"><strong>EMPTY TICKET</strong></div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      
        <!-- <table class="table" id="datatablex">
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Coin </th>
              <th>Package </th>
              <th>Amounts (USDT) </th>
              <th>Status </th>
              <th>Date </th>
              <th style="width:60px">Detail</th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>   -->


    <CSpinner v-show="mySpinner" color="primary" style="position:fixed;left: 50%;top:50%"/>

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>

    <CModal
      title="Add New Ticket"
      color="dark"
      id="modalAdd"      
      :show.sync="modalAdd"
    >
        <CForm>
        <CInput
          placeholder="Title"
          autocomplete="title"
          v-model="title"
        >            
        </CInput>
        <CTextarea
          placeholder="Your Description"
          v-model="description"
          />
      </CForm>
    <template #footer>
        <CButton @click="modalAdd = false" color="light">Cancel</CButton>
        <CButton @click="sendNow" color="dark">Send</CButton>
    </template>        
    </CModal>

    <CModal
      title="Edit Ticket"
      color="dark"
      id="modalEdit"      
      :show.sync="modalEdit"
    >
        <CForm>
        <CInput
          placeholder="Title"
          autocomplete="title"
          v-model="edtitle"
        >            
        </CInput>
        <CTextarea
          placeholder="Your Description"
          v-model="eddescription"
          />
          <input type="hidden" v-model="id_ticket">
      </CForm>
    <template #footer>
        <CButton @click="modalEdit = false" color="light">Cancel</CButton>
        <CButton @click="updateNow" color="dark">Send</CButton>
    </template>        
    </CModal>
    <CModal title="Error" :show.sync="myError" size="sm">
      Failed! Please check your connection again...
    </CModal>
    <CModal title="Success" :show.sync="mySuccess" size="sm">
      Success! Your ticket request has been received by our system. Please wait our team to solve your problem.
      <template #footer>
        <CButton @click="loadData()" color="dark">OK</CButton>
    </template>
    </CModal>

    <CModal
      title="Reply Ticket"
      color="dark"
      id="modalReply"      
      :show.sync="modalReply"
    >
        <CForm>
        <CInput
          placeholder="Title"
          autocomplete="title"
          readonly
          v-model="adtitle"
        >            
        </CInput>
        <CTextarea
          placeholder="Your Description"
          readonly
          v-model="addescription"
          />
          <hr>
          <CTextarea
          placeholder="Your Reply"
          v-model="reply"
          />
          <input type="hidden" v-model="id_ticket2">
      </CForm>
    <template #footer>
        <CButton @click="modalReply = false" color="light">Cancel</CButton>
        <CButton @click="replyNow" color="dark">Send</CButton>
    </template>        
    </CModal>
    <CModal title="Error" :show.sync="myError" size="sm">
      Failed! Please check your connection again...
    </CModal>
    <CModal title="Success" :show.sync="mySuccess" size="sm">
      Success! Your ticket request has been received by our system. Please wait our team to solve your problem.
      <template #footer>
        <CButton @click="loadData()" color="dark">OK</CButton>
    </template>
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 
let user = JSON.parse(localStorage.getItem('user'));
// let level = user.level;

export default {
  filters: {
    rupiah: function(value) {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    this.loadData();
  },
  data: function() {
        return {
            products:[],
            items:[],
            itemlengths:0,
            title:"",
            description:"",
            edtitle:"",
            eddescription:"",
            adtitle:"",
            addescription:"",
            id_ticket:"",
            id_ticket2:"",
            mySuccess:false,
            myError:false,
            mySpinner:false,
            modalAdd:false,
            modalEdit:false,
            modalReply:false,
            reply:"",
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    showModal:function(t,d,id){
      if(user.id_member=='0' || user.id_member==''){
        this.modalReply = true;this.adtitle = t;this.addescription=d;this.id_ticket2=id;
      }
      else{
        this.modalEdit = true;this.edtitle = t;this.eddescription=d;this.id_ticket=id;
      }
      
    },
    loadData: function() {
      this.mySuccess = false;
      let mid = user.id_member;
      let idm;
      if(mid=="") {
            idm = "0";
          }
          else{
            idm = mid;
          }
      // alert(mid);
        axios
        .post(
          process.env.VUE_APP_BASE_URL+"ticket/"+idm,false
        )
        .then((response) => {
          this.items=response.data.data;
          this.itemlengths = this.items.length;
          // alert(this.itemlengths);
        });
            
    },
    sendNow: function (event) {
      // Simple POST request with a JSON body using axios
      this.mySpinner = true;
      this.modalAdd = false;
      const stk = {
        mid: user.id_member,
        title: this.title,
        content: this.description,
      };
      // alert(JSON.stringify(stk));
      if ((this.title == null)) {
        this.myEmpty = true;
      }
      else{
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"addticket",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
            this.mySpinner = false;
            if (response.data == "sukses") {
              this.mySuccess = true;
              this.modalAdd = false;
              // if(this.mySuccess==false){
              //   this.loadData();
              // }
            } else {
              this.myError = true;
              this.modalAdd = false;
            }
          });
      }
    },
    updateNow: function (event) {
      // Simple POST request with a JSON body using axios
      this.mySpinner = true;
      this.modalEdit = false;
      const stk = {
        mid: user.id_member,
        id_ticket: this.id_ticket,
        title: this.edtitle,
        content: this.eddescription,
      };
      // alert(JSON.stringify(stk));
      if ((this.title == null)) {
        this.myEmpty = true;
      }
      else{
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"updateticket",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
            this.mySpinner = false;
            if (response.data == "sukses") {
              this.mySuccess = true;
              this.modalEdit = false;
              // if(this.mySuccess==false){
              //   this.loadData();
              // }
            } else {
              this.myError = true;
              this.modalEdit = false;
            }
          });
      }
    },
    replyNow: function (event) {
      // Simple POST request with a JSON body using axios
      this.mySpinner = true;
      this.modalReply = false;
      const stk2 = {
        id_ticket: this.id_ticket2,
        mid: user.id_member,
        reply: this.reply,
      };
      // alert(JSON.stringify(stk2));
      if ((this.title == null)){
        this.myEmpty = true;
      }
      else{
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"replyticket",
            stk2
          )
          .then((response) => {
            // alert(JSON.stringify(response.data));
            this.mySpinner = false;
            if (response.data == "sukses") {
              this.mySuccess = true;
              this.modalReply = false;
              // if(this.mySuccess==false){
                this.loadData();
              // }              
            } 
            else {
              this.myError = true;
              this.modalReply = false;
            }
          });
      }
    },
  }
}
</script>